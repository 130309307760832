<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('graduates')" @filter-div-status="datatable.filterStatus = $event"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('graduates')" @filter-div-status="datatable.filterStatus = $event"/>
            </template>
            <datatable-filter v-show="datatable.filterStatus" :filterButtonClass="'mt-1'" @filter="filter"
                              @filterClear="filterClearAndGetRow"
                              :exportExcel="true">
                <div>
                    <b-tabs content-class="py-5 position-relative" class="line-tabs">
                        <div class="tabs-bottom-line"></div>
                        <!-- Tab 1: Kişi Bilgileri -->
                        <b-tab>
                            <template #title>
                                <span v-html="computeTab1Name"></span>
                            </template>
                            <b-row>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('student_number')">
                                        <b-form-input type="number"
                                                      v-model="datatable.queryParams.filter.student_number"
                                                      v-check-min-max-value />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('national_number')">
                                        <b-form-input type="number" v-model="datatable.queryParams.filter.national_id"
                                                      v-check-min-max-value />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('name')">
                                        <b-form-input v-model="datatable.queryParams.filter.name" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('surname')">
                                        <b-form-input v-model="datatable.queryParams.filter.surname" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('foreign_student_q')">
                                        <yes-no-selectbox v-model="datatable.queryParams.filter.student_group" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('nationality')">
                                        <country-selectbox v-model="datatable.queryParams.filter.country"
                                                           valueType="code" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('current_city')">
                                        <city-selectbox v-model="datatable.queryParams.filter.city_id" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('current_district')">
                                        <district-outline-selectbox
                                            v-model="datatable.queryParams.filter.district_id"
                                            :city_id="datatable.queryParams.filter.city_id"/>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('birthdate_from')">
                                        <v-date-picker v-model="datatable.queryParams.filter.birthdate_greater" :locale="$i18n.locale"
                                                       is-expanded :masks="masks" :max-date="new Date()" :popover="{ 'visibility': 'click' }">

                                            <template v-slot="{ inputValue, inputEvents }">
                                                <b-input-group>
                                                    <b-form-input :readonly="true" :value="inputValue"
                                                                  v-on="inputEvents"></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button variant="outline-secondary" class="btn-40" disabled>
                                                            <i class="ri-calendar-line"></i></b-button>
                                                    </b-input-group-append>

                                                </b-input-group>
                                            </template>
                                        </v-date-picker>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="2">
                                    <b-form-group :label="$t('birthdate_to')">
                                        <v-date-picker v-model="datatable.queryParams.filter.birthdate_smaller" :locale="$i18n.locale"
                                                       is-expanded :masks="masks" :max-date="new Date()" :popover="{ 'visibility': 'click' }">

                                            <template v-slot="{ inputValue, inputEvents }">
                                                <b-input-group>
                                                    <b-form-input :readonly="true" :value="inputValue"
                                                                  v-on="inputEvents"></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button variant="outline-secondary" class="btn-40" disabled>
                                                            <i class="ri-calendar-line"></i></b-button>
                                                    </b-input-group-append>

                                                </b-input-group>
                                            </template>
                                        </v-date-picker>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-tab>
                        <!-- Tab 2: Mezuniyet Bilgileri -->
                        <b-tab>
                            <template #title>
                                <span v-html="computeTab2Name"></span>
                            </template>
                            <b-row>
                                <b-col cols="12" md="4" lg="3" xl="4">
                                    <b-form-group :label="$t('faculty')">
                                        <faculty-selectbox
                                            v-model="datatable.queryParams.filter.faculty_code" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="4">
                                    <b-form-group :label="$t('department')">
                                        <department-selectbox
                                            :faculty_code="datatable.queryParams.filter.faculty_code"
                                            :is_faculty_code_required="true"
                                            :multiple="false"
                                            v-model="datatable.queryParams.filter.department_code" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="4">
                                    <b-form-group :label="$t('program')">
                                        <program-selectbox
                                            :department_code="datatable.queryParams.filter.department_code"
                                            :is_department_code_required="true"
                                            :multiple="false"
                                            v-model="datatable.queryParams.filter.program_code" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="3">
                                    <b-form-group :label="$t('graduate_date_in')">
                                        <v-date-picker v-model="datatable.queryParams.filter.graduation_date" :locale="$i18n.locale"
                                                       is-expanded :masks="masks" :max-date="new Date()" :popover="{ 'visibility': 'click' }">

                                            <template v-slot="{ inputValue, inputEvents }">
                                                <b-input-group>
                                                    <b-form-input :readonly="true" :value="inputValue"
                                                                  v-on="inputEvents"></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button variant="outline-secondary" class="btn-40" disabled>
                                                            <i class="ri-calendar-line"></i></b-button>
                                                    </b-input-group-append>

                                                </b-input-group>
                                            </template>
                                        </v-date-picker>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="3">
                                    <b-form-group :label="$t('graduation_academic_year')">
                                        <academic-years-selectbox v-model="datatable.queryParams.filter.graduation_academic_year" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="3">
                                    <b-form-group :label="$t('graduation_semester')">
                                        <semester-type-selectbox v-model="datatable.queryParams.filter.graduation_semester" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="3">
                                    <b-form-group :label="$t('alumni_card_status')">
                                        <multi-selectbox
                                            :multiple="false"
                                            :options="cardStatusOptions"
                                            v-model="datatable.queryParams.filter.alumni_card_status" />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-tab>
                        <!-- Tab 3: Diğer -->
                        <b-tab>
                            <template #title>
                                <span v-html="computeTab3Name"></span>
                            </template>
                            <b-row>
                                <b-col cols="12" md="4" lg="3" xl="3">
                                    <b-form-group :label="$t('work_status')">
                                        <multi-selectbox
                                            :multiple="false"
                                            :options="workStatusOptions"
                                            v-model="datatable.queryParams.filter.working_status" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="3">
                                    <b-form-group :label="$t('not_working_reason')">
                                        <multi-selectbox
                                            :multiple="false"
                                            :options="notWorkStatusOptions"
                                            v-model="datatable.queryParams.filter.not_working_reason" />
                                    </b-form-group>
                                </b-col>
                                <!-- <b-col cols="12" md="4" lg="3" xl="3">
                                    <b-form-group :label="$t('graduation_scholarship_status')">
                                        <yes-no-selectbox v-model="datatable.queryParams.filter.graduation_scholarship_status" />
                                    </b-form-group>
                                </b-col> -->
                                <b-col cols="12" md="4" lg="3" xl="3">
                                    <b-form-group :label="$t('children_available')">
                                        <multi-selectbox
                                            :multiple="false"
                                            :options="childrenAvailableOptions"
                                            v-model="datatable.queryParams.filter.child_status" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="3">
                                    <b-form-group :label="$t('death_status')">
                                        <multi-selectbox
                                            :multiple="false"
                                            :options="deathOptions"
                                            v-model="datatable.queryParams.filter.death_status" />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="3">
                                    <b-form-group :label="$t('last_meeting_date_from')">
                                        <v-date-picker v-model="datatable.queryParams.filter.last_contact_greater" :locale="$i18n.locale"
                                                       is-expanded :masks="masks" :max-date="new Date()" :popover="{ 'visibility': 'click' }">

                                            <template v-slot="{ inputValue, inputEvents }">
                                                <b-input-group>
                                                    <b-form-input :readonly="true" :value="inputValue"
                                                                  v-on="inputEvents"></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button variant="outline-secondary" class="btn-40" disabled>
                                                            <i class="ri-calendar-line"></i></b-button>
                                                    </b-input-group-append>

                                                </b-input-group>
                                            </template>
                                        </v-date-picker>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="4" lg="3" xl="3">
                                    <b-form-group :label="$t('last_meeting_date_to')">
                                        <v-date-picker v-model="datatable.queryParams.filter.last_contact_smaller" :locale="$i18n.locale"
                                                       is-expanded :masks="masks" :max-date="new Date()" :popover="{ 'visibility': 'click' }">

                                            <template v-slot="{ inputValue, inputEvents }">
                                                <b-input-group>
                                                    <b-form-input :readonly="true" :value="inputValue"
                                                                  v-on="inputEvents"></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button variant="outline-secondary" class="btn-40" disabled>
                                                            <i class="ri-calendar-line"></i></b-button>
                                                    </b-input-group-append>

                                                </b-input-group>
                                            </template>
                                        </v-date-picker>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-tab>
                    </b-tabs>

                </div>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                       @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"/>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';

// Components
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox"
import CitySelectbox from "@/components/interactive-fields/CitySelectbox"
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox"
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox"
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox"
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox"
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox"
import SemesterTypeSelectbox from "@/components/interactive-fields/SemesterTypeSelectbox"
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

// Services
// import StudentProgramService from '@/services/StudentProgramService';
// import StudentDocumentService from '@/services/StudentDocumentService';
// import PrepStudentService from '@/services/PrepStudentService';
import AlumniService from '@/services/AlumniService';

// Data
// import documentData from '@/modules/students/data/Documents'

// Other
import qs from 'qs'
import {EventBus} from "@/main";
// import Base from "@/plugins/Base";


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,

        YesNoSelectbox,
        CitySelectbox,
        DistrictOutlineSelectbox,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        AcademicYearsSelectbox,
        SemesterTypeSelectbox,
        MultiSelectbox,
    },
    metaInfo() {
        return {
            title: this.$t('graduates')
        }
    },
    computed: {
        computeTab1Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.student_number)) count++;
            if (this.isNotNull(filters.national_id)) count++;
            if (this.isNotNull(filters.name)) count++;
            if (this.isNotNull(filters.surname)) count++;
            if (this.isNotNull(filters.student_group)) count++;
            if (this.isNotNull(filters.country)) count++;
            if (this.isNotNull(filters.city_id)) count++;
            if (this.isNotNull(filters.district_id)) count++;
            if (this.isNotNull(filters.birthdate_greater)) count++;
            if (this.isNotNull(filters.birthdate_smaller)) count++;

            if (count) {
                let filterButton = '<span class="badge badge-primary ml-1">' + count + '</span>';
                return this.$t('personal_information') + filterButton
            }
            return this.$t('personal_information');
        },
        computeTab2Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.faculty_code)) count++;
            if (this.isNotNull(filters.department_code)) count++;
            if (this.isNotNull(filters.program_code)) count++;
            if (this.isNotNull(filters.graduation_date)) count++;
            if (this.isNotNull(filters.graduation_academic_year)) count++;
            if (this.isNotNull(filters.graduation_semester)) count++;
            if (this.isNotNull(filters.alumni_card_status)) count++;

            if (count) {
                let filterButton = '<span class="badge badge-primary ml-1">' + count + '</span>';
                return this.$t('graduation_information') + filterButton
            }
            return this.$t('graduation_information');
        },
        computeTab3Name() {
            let count = 0;
            let filters = this.datatable.queryParams.filter;

            if (this.isNotNull(filters.working_status)) count++;
            if (this.isNotNull(filters.not_working_reason)) count++;
            // if (this.isNotNull(filters.graduation_scholarship_status)) count++;
            if (this.isNotNull(filters.child_status)) count++;
            if (this.isNotNull(filters.death_status)) count++;
            if (this.isNotNull(filters.last_contact_greater)) count++;
            if (this.isNotNull(filters.last_contact_smaller)) count++;

            if (count) {
                let filterButton = '<span class="badge badge-primary ml-1">' + count + '</span>';
                return this.$t('other') + filterButton
            }
            return this.$t('other');
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('view_and_edit'),
                                class: 'ri-search-eye-line',
                                //permission: 'studentprogram_show',
                                callback: (row) => {
                                    this.$router.push('/graduates/'+row.student_id)
                                }
                            },
                            {
                                text: this.$t('graduate_card_create'),
                                class: 'ri-chat-new-line',
                                //permission: 'studentprogram_show',
                                callback: (row) => {
                                    this.$router.push('/graduates/card/create/'+row.student_id)
                                }
                            },
                            {
                                text: this.$t('create_password'),
                                class: 'ri-key-line',
                                //permission: 'studentprogram_show',
                                callback: (row) => {
                                    this.$toast.success(this.$t('password_sent'))
                                }
                            }
                        ]
                    },
                    {
                        label: this.$t('student_number'),
                        field: 'student_number',
                    },
                    {
                        label: this.$t('national_number'),
                        field: 'national_id',
                    },
                    {
                        label: this.$t('name'),
                        field: 'name',
                    },
                    {
                        label: this.$t('surname'),
                        field: 'surname',
                    },
                    {
                        label: this.$t('email'),
                        field: 'alumni_email',
                    },
                    {
                        label: this.$t('mobile_number'),
                        field: 'phone',
                    },
                    {
                        label: this.$t('city_id'),
                        field: 'city',
                    },
                    {
                        label: this.$t('district'),
                        field: 'district',
                    },
                    {
                        label: this.$t('session'),
                        field: 'semester',
                    },
                    {
                        label: this.$t('period'),
                        field: 'period',
                    },
                    {
                        label: this.$t('faculty'),
                        field: this.getLocaleField('faculty_code'),
                    },
                    {
                        label: this.$t('department'),
                        field: this.getLocaleField('department_code'),
                    },
                    {
                        label: this.$t('program'),
                        field: this.getLocaleField('program_code'),
                    },
                    {
                        label: this.$t('work_status'),
                        field: 'working_status',
                    },
                    {
                        label: this.$t('not_working_reason'),
                        field: this.getLocaleField('not_working_reason'),
                    },
                    {
                        label: this.$t('death_status'),
                        field: this.getLocaleField('death_status'),
                    },
                    {
                        label: this.$t('marriage_status'),
                        field: 'marital_status',
                    }
                ],
                rows: [],
                // total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },

            masks: {
                input: "DD-MM-YYYY",
            },

            cardStatusOptions: [
                {
                    text: this.$t('card_created'),
                    value: 'card_created'
                },
                {
                    text: this.$t('card_not_created'),
                    value: 'card_not_created'
                }
            ],

            workStatusOptions: [
                {
                    text: this.$t('working'),
                    value: 'working'
                },
                {
                    text: this.$t('not_working'),
                    value: 'not_working'
                }
            ],

            notWorkStatusOptions: [
                {
                    text: this.$t('not_working_in_military'),
                    value: 'not_working_in_military'
                },
                {
                    text: this.$t('not_working_no_info'),
                    value: 'not_working_no_info'
                },
                {
                    text: this.$t('not_working_other'),
                    value: 'not_working_other'
                },
                {
                    text: this.$t('not_working_education'),
                    value: 'not_working_education'
                },
                {
                    text: this.$t('not_working_marriage'),
                    value: 'not_working_marriage'
                },
                {
                    text: this.$t('not_working_searching_job'),
                    value: 'not_working_searching_job'
                }
            ],

            childrenAvailableOptions: [
                {
                    text: this.$t('available'),
                    value: 'available'
                },
                {
                    text: this.$t('none'),
                    value: 'none'
                }
            ],

            deathOptions: [
                {
                    text: this.$t('in_life'),
                    value: 'in_life'
                },
                {
                    text: this.$t('died'),
                    value: 'died'
                }
            ]
        }
    },
    created() {
        this.$store.dispatch('students/initLayouts');
        if (this.$store.getters['students/getFilters']) {
            this.datatable.queryParams.filter = this.$store.getters['students/getFilters'];
        } else {
            this.filterClear();
        }
    },
    mounted() {

    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {
                // Tab 1
                student_number: null,
                national_id: null,
                name: null,
                surname: null,
                student_group: null,
                country: null,
                city_id: null,
                district_id: null,
                birthdate_greater: null,
                birthdate_smaller: null,

                // Tab2
                faculty_code: null,
                department_code: null,
                program_code: null,
                graduation_date: null,
                graduation_academic_year: null,
                graduation_semester: null,
                alumni_card_status: null,

                // Tab3
                working_status: null,
                not_working_reason: null,
                // graduation_scholarship_status: null,
                child_status: null,
                death_status: null,
                last_contact_greater: null,
                last_contact_smaller: null


            }
        },
        filterClearAndGetRow() {
            this.filterClear();
            this.getRows();
            // this.datatable.rows = null
            // this.datatable.total = null
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        async postponementAction() {
            const isValid = await this.$refs.postponementForm.validate();
            if (isValid) {
                EventBus.$emit('postponementDataSend', this.postponementForm);
                this.postponementForm = {}
            }
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return AlumniService.getAll(config)
                                       .then((response) => {
                                           this.datatable.rows = response.data.data;
                                        //    this.datatable.total = response.data.pagination.total;
                                       })
                                       .finally(() => {
                                           this.datatable.isLoading = false;
                                       });
            },
    }
}
</script>

